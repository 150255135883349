import { parseQueryStrings } from '@crehana/utils';

import getNextUrl from './getNextUrl';

const redirectWithQueryParams = (url: string) => {
  const queryParams = window.location.search;
  const nextUrl = getNextUrl();

  /**
   * We need to remove the `next` query param if it contains /registro-extra/
   * when we change to /entrar/ so we don't trigger the onboarding after login,
   * all of this avoiding loosing other query params like utms
   */
  if (url === '/entrar/' && nextUrl.includes('registro-extra')) {
    const qs = parseQueryStrings(window.location.search);

    if (qs) {
      delete qs.next;

      const search = Object.keys(qs).reduce(
        (acc, key) => `${acc}${acc ? '&' : ''}${key}=${qs[key]}`,
        '',
      );

      window.location.href = `${url}${search ? `?${search}` : ''}`;
      return;
    }
  }

  window.location.href = `${url}${queryParams}`;
};

export default redirectWithQueryParams;
